



























import { Component, Mixins } from 'vue-property-decorator'
import PBeatTable from '@elitepage/components/PBeatTable.vue'

import { fetchPrivateBeats } from '~/api/beats'
import { ElitepageStore } from '@elitepage/store/elitepage'
import { InfiniteLoadingSetState } from '~/mixins/infinite-loading'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

@Component({ components: { PBeatTable, ValidationProvider, ValidationObserver } })
export default class BeatsPrivateVault extends Mixins(InfiniteLoadingSetState) {
	password = ''
	apiCallMade = false
	beats: IBeat[] = []
	requestError = ''

	@ElitepageStore.State('elitepage') elitepage: IElitepage

	// get hasError() {
	//     return this.errors.has('password') || this.requestError
	// }

	async updateBeatsTable($state: any, offset: number) {
		try {
			this.requestError = ''
			const beats = await fetchPrivateBeats(this.elitepage.producer_id, this.password, offset)
			this.apiCallMade = true
			this.beats.length > 0 ? this.beats.push(...beats) : (this.beats = beats)
			// set loading state for vue-infinite-loading
			this.setLoadingStateAndUpdatePlayerQueue($state, beats)
		} catch (error) {
			console.error(error)
			this.requestError = 'The password you wrote is wrong'
		}
	}
}
